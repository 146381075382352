<template>
    <div class="noChart">
        <h1>You need to <span> access at least 3 Skills </span> to show this.</h1>
    </div>
</template>

<script>
export default {
    name: "EmptySpyderWebChart",
};
</script>

<style scoped lang="scss">
.noChart {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        width: 80%;
        color: #0f172a;
        text-align: center;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px; /* 175% */
        letter-spacing: -0.48px;
        span {
            font-weight: 700;
        }
    }
}
</style>
