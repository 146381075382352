<template>
    <div class="tooltip" v-if="showTooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
        <h3>{{ circleData.name }}</h3>
        <p><span> Score:</span> {{ circleData.score }}%</p>
        <p><span>Time:</span> {{ getTime(circleData.time) }}</p>
    </div>
</template>

<script>
export default {
    name: "CircleTooltip",
    props: {
        circleData: Object, // Data for the hovered circle
        showTooltip: Boolean, // Flag to show/hide the tooltip
        tooltipX: Number, // X position of the tooltip
        tooltipY: Number, // Y position of the tooltip
    },
    methods: {
        getTime(time) {
            // Convert the number to a string and then split by dot
            const timeString = time?.toString();
            const timearr = timeString?.split(/\./);

            // Assuming timearr[0] contains minutes and timearr[1] contains seconds
            if (timearr) {
                return timearr[0] + "min" + timearr[1] + "sec";
            }
        },
    },
};
</script>

<style scoped lang="scss">
.tooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 20px;
    z-index: 999; /* Ensure the tooltip is on top of other elements */
    h3 {
        // padding: 0 2rem;
        color: var(#0f172a);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        line-height: 42px; /* 116.667% */
        letter-spacing: -0.72px;
        margin-right: 0;
    }
    p {
        padding: 0 1rem;
    }
    span {
        padding-right: 0.4rem;
        color: var(#0f172a);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px; /* 116.667% */
        letter-spacing: -0.72px;
    }
}
</style>
