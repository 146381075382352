<template>
    <div class="skill-mapping">
        <svg :width="width" :height="height">
            <circle
                v-for="(skill, index) in skills"
                :key="index"
                :cx="skill.x"
                :cy="skill.y"
                r="20"
                fill="#0078d4"
                stroke="white"
                stroke-width="2"
                @mouseover="showTooltip(skill)"
                @mouseout="hideTooltip"
            />
            <text v-for="(skill, index) in skills" :key="index" :x="skill.labelX" :y="skill.labelY" text-anchor="middle" font-size="12" fill="white">
                {{ skill.name }}
            </text>
        </svg>
        <div v-if="tooltip" class="tooltip">{{ tooltip }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            width: 1000,
            height: 700,
            skills: [
                { name: "Python", x: 300, y: 400, labelX: 300, labelY: 440 },
                {
                    name: "JavaScript",
                    x: 500,
                    y: 200,
                    labelX: 500,
                    labelY: 240,
                },
                { name: "SQL", x: 700, y: 400, labelX: 700, labelY: 440 },
                { name: "HTML/CSS", x: 500, y: 600, labelX: 500, labelY: 640 },
                { name: "D3.js", x: 300, y: 200, labelX: 300, labelY: 240 },
            ],
            tooltip: null,
        };
    },
    methods: {
        showTooltip(skill) {
            this.tooltip = skill.name;
        },
        hideTooltip() {
            this.tooltip = null;
        },
    },
};
</script>

<style scoped>
.skill-mapping {
    text-align: center;
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    z-index: 1;
}
</style>
