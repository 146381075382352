<template>
    <div class="rounded-xl bg-white flex flex-col justify-center items-center">
        <div class="w-full px-10 flex flex-row pt-5 items-center">
            <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="comparison" />
            <span class="text-xl text-[#a3aed0] font-medium text-left ml-2">Comparison</span>
        </div>
        <div class="grid grid-cols-3 gap-5 px-10 w-full mt-5">
            <span v-for="(assessment, index) in data[0]?.results" :key="index"> <span class="inline-block w-2 h-2 bg-black rounded-full mr-2"></span> {{ assessment.assessmentName }} </span>
        </div>
        <div ref="chart"></div>
    </div>
</template>

<script>
import * as d3 from "d3";
import { useStore } from "@/store";

export default {
    name: "BarCompare",
    props: {
        data: Array,
        chartWidth: Number,
        chartHeight: Number,
        showLabel: Boolean,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    mounted() {
        console.log("the data is", this.data, "---------------------------------------------------===============");
        let margin = { top: 50, right: 20, bottom: 30, left: 40 },
            width = 720 - margin.left - margin.right,
            height = 450 - margin.top - margin.bottom;

        let svg = d3
            .select(this.$refs.chart)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        let persons = this.data.map((d) => d.name);
        //   let assessments = [].concat(...this.data.map((d) => d.results.map((r) => r.assessmentName)));
        let assessments = [...new Set([].concat(...this.data.map((d) => d.results.map((r) => r.assessmentName))))];

        let x0 = d3.scaleBand().domain(persons).rangeRound([0, width]).paddingInner(0.4);

        let x1 = d3.scaleBand().domain(assessments).rangeRound([0, x0.bandwidth()]).padding(0.1);

        let y = d3
            .scaleLinear()
            .domain([0, d3.max(this.data, (d) => d3.max(d.results, (r) => r.totalPoints))])
            .rangeRound([height, 0]);

        let z = this.data.length === 3 ? d3.scaleOrdinal().range(["#EABC49", "#05CD99", "#3361FF"]) : d3.scaleOrdinal().range(["#3361FF", "#05CD99"]);
        var tooltip = d3
            .select("body")
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("z-index", "10")
            .style("background-color", "#fff")
            .style("color", "black")
            .style("padding", "8px")
            .style("border", "1px solid black")
            .style("border-radius", "8px")
            .style("visibility", "hidden");

        svg.append("g")
            .selectAll("g")
            .data(this.data)
            .enter()
            .append("g")
            .attr("transform", (d) => "translate(" + x0(d.name) + ",0)")
            .attr("fill", (d, i) => z(i)) // Use the index of the candidate to determine the color
            .selectAll("rect")
            .data((d) => d.results)
            .enter()
            .append("rect")
            .attr("x", (d) => x1(d.assessmentName))
            .attr("y", (d) => y(d.totalPoints))
            .attr("width", 30)
            .attr("height", (d) => height - y(d.totalPoints))
            .attr("fill", (d, i, j) => z(j)) // Use the index of the result within the candidate to determine the color
            .attr("stroke-width", 2)
            .attr("rx", 10) // Border radius for the x-axis
            .attr("ry", 10)
            .on("mouseenter", function (d) {
                // Show the tooltip on hover
                let data = d.target.__data__;
                tooltip
                    .style("visibility", "visible")
                    .text(`${data.assessmentName}: ${data.totalPoints} / ${data.quesionsNbr}`)
                    .style("left", d.pageX + "px")
                    .style("top", d.pageY - 28 + "px");
            })
            .on("mouseout", function () {
                // Hide the tooltip when mouse moves out
                tooltip.style("visibility", "hidden");
            });

        // Add labels under the bars without rotation
        // svg.append("g")
        //     .selectAll("g")
        //     .data(this.data)
        //     .enter()
        //     .append("g")
        //     .attr("transform", (d) => "translate(" + x0(d.name) + ",0)")
        //     .selectAll("text")
        //     .data((d) => d.results)
        //     .enter()
        //     .append("text")
        //     .attr("x", (d) => x1(d.assessmentName) + 15) // Adjust the x-coordinate as needed
        //     .attr("y", () => height + 10) // Adjust the y-coordinate to position the text under the bars
        //     .text((d) => {
        //         let words = d.assessmentName.split(" ");
        //         if (words.length > 1) {
        //             console.log("---=-=-=-=-=-=--=-==-=-=-==-", { words });
        //             words = words.join("\n");
        //             return words;
        //         }
        //         console.log({ words });
        //         return words;
        //     })
        //     .style("font-size", "10px")
        //     .style("fill", "black")
        //     .style("text-anchor", "middle")
        //     .attr("dy", "0.5em"); // Adjust the dy attribute for better vertical positioning

        svg.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x0))
            .selectAll("text")
            .style("fill", "#a3aed0")
            .style("font-size", "12px");

        svg.select(".axis path").style("stroke", "#a3aed0");
        //   svg.append("g")
        //     .attr("class", "axis")
        // .call(d3.axisLeft(y).ticks(null, "s"))
        // .append("text")
        // .attr("x", 2)
        // .attr("y", y(y.ticks()pop()) + 0.5)
        // .attr("dy", "0.32em")
        // .attr("fill", "#000")
        // .attr("font-weight", "bold")
        // .attr("text-anchor", "start")
        // .text("Score");

        // Remove the ticks on the x-axis
        svg.select(".axis").selectAll(".tick").attr("fill", "#fff");

        const maxValue = d3.max(this.data, (d) => d3.max(d.results, (r) => r.totalPoints));
        svg.append("line")
            .attr("x1", 0)
            .attr("y1", y(maxValue))
            .attr("x2", width)
            .attr("y2", y(maxValue))
            .attr("stroke", "#2196f3")
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", "5,5")
            .append("text");

        svg.append("text")
            .attr("x", width - 10)
            .attr("y", y(maxValue) - 10)
            .attr("text-anchor", "end")
            .text(maxValue);

        // // Distribute the labels horizontally based on data names
        // let legend = svg
        //     .append("g")
        //     .attr("font-family", "sans-serif")
        //     .attr("font-size", 10)
        //     .attr("text-anchor", "end")
        //     .selectAll("g")
        //     .data(this.data)
        //     .enter()
        //     .append("g")
        //     .attr("transform", (d, i) => "translate(" + i * 180 + ",-60)");

        // // Add a text label for the name
        // legend
        //     .append("text")
        //     .attr("x", 140)
        //     .attr("y", 9.5)
        //     .attr("dy", ".32em")
        //     .text((d) => d.name);

        // // Add a circle for the color indicator
        // legend
        //     .append("circle")
        //     .attr("cx", 50)
        //     .attr("cy", 9.5)
        //     .attr("r", 9.5)
        //     .attr("fill", (d, i) => z(i));
        // Distribute the labels horizontally
        // let legend = svg
        //     .append("g")
        //     .attr("font-family", "sans-serif")
        //     .attr("font-size", 10)
        //     .attr("text-anchor", "end")
        //     .selectAll("g")
        //     .data(assessments.slice())
        //     .enter()
        //     .append("g")
        //     // .attr("transform", function (d, i) {
        //     //     return "translate(" + i * 120 + ",-80)";
        //     // })
        //     .attr("transform", function (d, i) {
        //         const col = i % 3; // Display 3 items per row
        //         const row = Math.floor(i / 3); // Calculate the row index
        //         const x = col * 120; // Adjust the x position based on the column index
        //         const y = row * 30; // Adjust the y position based on the row index
        //         return "translate(" + x + "," + y + ")"; // Apply the translation
        //     });

        // // Add a circle for the color indicator
        // legend.append("circle").attr("cx", 100).attr("cy", 9.5).attr("r", 5.5).attr("fill", "black");

        // // Add a text label for the assessment name
        // legend
        //     .append("text")
        //     .attr("x", 140)
        //     .attr("y", 9.5)
        //     .attr("dy", ".32em")
        //     .text(function (d) {
        //         return d;
        //     });

        // svg.append("text").attr("x", 80).attr("y", -100).attr("text-anchor", "middle").attr("font-size", "44px").attr("font-weight", "900").attr("fill", "#000").text("Overview");
    },
};
</script>

<style lang="scss" scoped></style>
