<template>
    <div class="chartsWrapper">
        <div class="wrapper">
            <DiskVue />
            <HistogramChart />
        </div>
        <LineGraph />
        <SunburstChart :diskData="this.diskData" />
        <SkillMapping />
    </div>
</template>

<script>
import DiskVue from "./DiskChart.vue";
import HistogramChart from "./Histogram.vue";
import LineGraph from "./Graph.vue";
import SunburstChart from "./Sunburst.vue";
import SkillMapping from "./SkillMapping.vue";
export default {
    name: "TestChart",
    components: {
        DiskVue,
        HistogramChart,
        LineGraph,
        SunburstChart,
        SkillMapping,
    },
    data() {
        return {
            diskData: [
                { label: "Data A", value: 30 },
                { label: "Data B", value: 20 },
                { label: "Data C", value: 25 },
                { label: "Data D", value: 15 },
                { label: "Data E", value: 20 },
            ],
        };
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    flex-direction: row;
    margin: 10% 0;
}
</style>
