<template>
    <div class="line-chart-container">
        <!-- Chart container element -->
        <div ref="chartContainer" class="chart-svg-container" @mousemove="handlePointerMoved" @mouseleave="handlePointerLeft">
            <div class="tooltip" :style="tooltipStyle" v-if="tooltipVisible">
                {{ tooltipContent }}
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "LineChart",
    props: {
        graphData: Array, // Data for the chart
        // chartWidth: Number,
        chartHeight: Number,
    },
    data() {
        return {
            // Define any other chart-related data properties here
            width: 700,
            height: this.chartHeight,
            marginTop: 20,
            marginRight: 30,
            marginBottom: 30,
            marginLeft: 40,
            tooltipStyle: {
                display: "none",
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #ccc",
                padding: "5px",
                pointerEvents: "none", // Prevent the tooltip from blocking pointer events
            },
            tooltipContent: "",
            tooltipVisible: false,
            // graphData: [
            //   // Provide your dataset here
            //   { Date: new Date("2023-01-01"), Close: 20 },
            //   { Date: new Date("2023-01-02"), Close: 60 },
            //   { Date: new Date("2023-01-03"), Close: 30 },
            //   { Date: new Date("2023-01-04"), Close: 50 },
            // ],
        };
    },
    mounted() {
        if (window.innerWidth > 900) {
            if (window.innerWidth > 1260) {
                this.width = 700;
            } else this.width = 500;
        } else {
            this.width = window.innerWidth * 0.6;
        }
        this.createChart();
        // console.log('width............', this.width)

        window.addEventListener("resize", this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth > 900) {
                if (window.innerWidth > 1260) {
                    this.width = 700;
                } else this.width = 500;
            } else {
                this.width = window.innerWidth * 0.6;
            }
            // else{
            //
            //     }h
            // console.log('function width', this.width)
            this.createChart();
        },
        createChart() {
            const container = d3.select(this.$refs.chartContainer);
            container.select("svg").remove();
            // Define x and y scales, line generator, and other chart elements here
            const x = d3
                .scaleUtc()
                .domain(d3.extent(this.graphData, (d) => d.Date))
                .range([this.marginLeft, this.width - this.marginRight]);

            const y = d3
                .scaleLinear()
                .domain([0, d3.max(this.graphData, (d) => d.Close)])
                .nice()
                .range([this.height - this.marginBottom, this.marginTop]);

            const line = d3
                .line()
                .x((d) => x(d.Date))
                .y((d) => y(d.Close))
                .curve(d3.curveCardinal); // Use curveCardinal for smooth curves

            const svg = container.append("svg").attr("viewBox", [0, 0, this.width, this.height]).attr("width", this.width).attr("height", this.height);

            // Append x-axis
            svg.append("g")
                .attr("transform", `translate(0, ${this.height - this.marginBottom})`)
                .call(
                    d3
                        .axisBottom(x)
                        .ticks(this.width / 80)
                        .tickSizeOuter(0),
                );

            svg.selectAll(".tick line").style("stroke", "#a3aed0").attr("stroke-width", "5px").attr("stroke-height", "50px");

            svg.select(".domain")
                .attr("stroke", "#fff") // Set the line color
                .attr("stroke-width", "2px") // Set the line width
                .style("stroke-dasharray", "5,5");

            // Append y-axis
            svg.append("g")
                .attr("transform", `translate(${this.marginLeft}, 0)`)
                .call(d3.axisLeft(y).ticks(this.height / 40))
                .call((g) => g.select(".domain").remove())
                .selectAll(".tick line")
                .remove()
                .append("text")
                .attr("x", -this.marginLeft)
                .attr("y", 10)
                .attr("fill", "#a3aed0")
                .attr("text-anchor", "start")
                .text("↑ Daily Close ($)");

            // Style the y-axis text
            svg.selectAll(".tick text")
                .attr("font-size", "12px") // Set font size
                .attr("fill", "#a3aed0");

            // Append the area under the line graph as a polygon
            const area = d3
                .area()
                .x((d) => x(d.Date))
                .y0(this.height - this.marginBottom)
                .y1((d) => y(d.Close))
                .curve(d3.curveCardinal);

            svg.append("linearGradient")
                .attr("id", "gradient")
                .attr("x1", "0%")
                .attr("y1", "0%")
                .attr("x2", "0%")
                .attr("y2", "100%")
                .selectAll("stop")
                .data([
                    { offset: "30%", color: "rgba(234,233,254,1) " },
                    { offset: "66%", color: "rgba(255,255,255,0)" },
                ])
                .enter()
                .append("stop")
                .attr("offset", (d) => d.offset)
                .attr("stop-color", (d) => d.color);

            svg.append("path").attr("class", "path-area").attr("fill", "url(#gradient)").attr("d", area(this.graphData));

            // Append the line path with smooth curves
            svg.append("path").attr("class", "path-line").attr("fill", "none").attr("stroke", "#7e84fb").attr("stroke-width", 2).attr("d", line(this.graphData));

            // Create and append the tooltip container (you can add tooltip functionality here)
            //   const tooltip = svg.append("g");

            // Add event listeners for interactions (e.g., mouseover, mouseout)
            svg.on("pointerenter pointermove", this.handlePointerMoved);
            svg.on("pointerleave", this.handlePointerLeft);

            const bisect = d3.bisector((d) => d.Date).center;

            this.updateChart = (event) => {
                // Implement chart updates (e.g., tooltip) based on user interactions here
                const mouseX = event.clientX - this.$refs.chartContainer.getBoundingClientRect().left;
                const index = bisect(this.graphData, x.invert(mouseX));
                const dataPoint = this.graphData[index];

                if (dataPoint) {
                    this.tooltipContent = `Date: ${dataPoint.Date.toDateString()}, Close: $${dataPoint.Close.toFixed(2)}`;
                    this.tooltipStyle.left = event.pageX + "px";
                    this.tooltipStyle.top = event.pageY + "px";
                    this.tooltipVisible = true;
                } else {
                    this.tooltipVisible = false;
                }
            };
        },
        handlePointerMoved(event) {
            // Handle pointer moved event (e.g., update tooltip)
            this.updateChart(event);
        },
        handlePointerLeft() {
            // Handle pointer left event (e.g., hide tooltip)
            this.tooltipVisible = false;
        },
    },
};
</script>

<style scoped>
.chart-svg-container {
    /* Apply a drop shadow to the SVG container */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* width: 700px; */
}

.path-line {
    fill: none;
    stroke-width: 2;
}

.path-area {
    fill-opacity: 0.6; /* Adjust the opacity as needed */
}

/* Add any other styles as needed for the chart elements */
</style>
