<template>
    <div class="histogramContainer">
        <div class="w-full flex justify-center">
            <svg ref="histogramChart" :width="chartWidth" :height="chartHeight">
                <!-- Add a linear gradient for the stripes -->
                <!-- <defs>
                    <linearGradient id="stripes" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style="stop-color: #33e6ff; stop-opacity: 1" />
                        <stop offset="0%" style="stop-color: #33e6ff; stop-opacity: 1">
                            <animate attributeName="offset" values="-1; 1" dur="0.5s" repeatCount="indefinite" />
                        </stop>
                        <stop offset="100%" style="stop-color: #33e6ff; stop-opacity: 1">
                            <animate attributeName="offset" values="0; 2" dur="0.5s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>
                </defs> -->
                <!-- Rest of the chart code remains the same -->
                <g ref="chartGroup" transform="translate(40, 20)"></g>
                <g ref="yAxis" class="y-axis" :transform="`translate(40, 20)`"></g>
            </svg>
            <div class="tooltip" :style="tooltipStyle">
                {{ tooltipContent }}
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "HistogramVue",
    props: {
        histogramData: Array,
        chartWidth: Number,
        chartHeight: Number,
        showLabel: Boolean,
    },
    data() {
        return {
            componentKey: 0,
            data: [],
            tooltipStyle: {
                opacity: 0,
                left: "0px",
                top: "0px",
            },
            tooltipContent: "", // Content to display in the tooltip
            activeBar: null, // Track the currently active bar
            tooltipHideTimeout: null, // Timeout to hide the tooltip
            colors: ["#ff5733", "#33ff57", "#5733ff"], // Define your colors here
            xAxisTitle: "X-Axis Title", // Define your X-axis title
            yAxisTitle: "Y-Axis Title", // Define your Y-axis title
        };
    },

    watch: {
        // Watch for changes to the score prop
        histogramData: {
            async handler(newVal, oldVal) {
                console.log("Watcher Triggered - Old Value: histogramData2histogramData2", oldVal);
                console.log("Watcher Triggered - New Value: histogramData2histogramData2", newVal);
                this.data = newVal;
                await this.drawHistgram(newVal);
                this.forceRerender();
                // Update the local data variable or trigger necessary actions
            },
            immediate: true, // Trigger the watcher immediately on component creation
        },
    },

    computed: {
        innerWidth() {
            return this.chartWidth - 30; // Adjust for left and right margins
        },
        innerHeight() {
            return 200 - 20 - 20; // Adjust for top and bottom margins
        },
    },
    mounted() {
        // setTimeout(() => {
        this.drawHistgram(this.data);
        // }, 800);
    },
    methods: {
        forceRerender() {
            // Incrementing the key value triggers a re-render
            this.componentKey += 1;
        },
        async drawHistgram(data) {
            const svg = d3.select(this.$refs.histogramChart);
            svg.selectAll(".bar").remove(); // Remove bars
            svg.selectAll(".histogram-label").remove();

            console.log({ dataaaaaaaaaaa: data });
            console.log({ lengthhhsdfsgfgwee: data.length });
            if (data.length > 0) {
                const x = d3
                    .scaleBand()
                    .domain(data.map((d, i) => `${d.label}-${i}`))
                    .range([0, this.innerWidth])
                    .padding(0.3);

                const y = d3
                    .scaleLinear()
                    .domain([0, d3.max(data, (d) => d.value)])
                    .nice()
                    .range([this.innerHeight, 0]);

                const g = d3.select(this.$refs.chartGroup);
                //                 background: rgb(123,42,255);
                // background: linear-gradient(90deg, rgba(123,42,255,1) 0%, rgba(0,174,240,1) 100%);

                // Create and render the x-axis labels directly below the bars
                g.selectAll(".bar")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", "bar")
                    .attr("x", (d, i) => x(`${d.label}-${i}`))
                    .attr("y", (d) => y(d.value))
                    .attr("width", x.bandwidth())
                    .attr("height", (d) => this.innerHeight - y(d.value))
                    .attr("fill", "#3968FA")
                    .attr("stroke-width", 2)
                    .on("mouseover", (event, d) => this.handleBarHover(event, d))
                    .on("mouseout", () => this.handleBarMouseOut())
                    .attr("rx", 10) // Border radius for the x-axis
                    .attr("ry", 10);

                // Add labels below the bars
                this.showLabel
                    ? g
                          .selectAll(".label")
                          .data(data)
                          .enter()
                          .append("text")
                          .attr("class", "histogram-label")
                          .attr("x", (d, i) => x(`${d.label}-${i}`) + x.bandwidth() / 2)
                          .attr("y", this.innerHeight + 30) // Adjust the position as needed
                          .style("text-anchor", "middle")
                          .style("fill", "#1b2559")
                          .style("font-size", "12px")
                          .style("font-weight", "bold")
                          .text((d) => d.label)
                          .attr("transform", function (d) {
                              const labelX = x(d.label) + x.bandwidth() / 2;
                              const labelY = this.innerHeight + 30; // Adjust the position as needed
                              return `translate(${labelX}, ${labelY}) rotate(-45)`;
                          })
                    : "";
                // const topValue = y(y.domain()[1]);

                // Create and render the y-axis
                d3.select(this.$refs.yAxis).call(d3.axisLeft(y).ticks(5).tickSize(0)).select(".domain").remove().selectAll(".tick line").remove();

                // .append("line")
                // .attr("x1", 0) // Start x-coordinate
                // .attr("x2", this.innerWidth) // End x-coordinate
                // .attr("y1", topValue) // Start y-coordinate (top value)
                // .attr("y2", topValue) // End y-coordinate (top value)
                // .style("stroke-dasharray", "4 4") // Set the line to be dotted
                // .style("stroke", "black");

                const maxValue = d3.max(data, (d) => d.value);
                g.append("line").attr("class", "highlight-line").attr("x1", 0).attr("x2", this.innerWidth).attr("y1", y(maxValue)).attr("y2", y(maxValue));
                g.select(".highlight-line").attr("stroke", "#2196f3").attr("stroke-dasharray", "5,5");
                document.addEventListener("mousemove", (event) => {
                    if (this.tooltipStyle.opacity > 0) {
                        this.updateTooltipPosition(event);
                    }
                });
            }
        },
        handleBarHover(event, d) {
            if (this.activeBar && this.activeBar !== event.target) {
                d3.select(this.activeBar).attr("opacity", 1);
            }

            d3.select(event.target).attr("opacity", 0.7);

            this.activeBar = event.target;
            this.tooltipContent = `${d.label}: ${d.value}`;
            this.tooltipStyle.opacity = 0.9;
            clearTimeout(this.tooltipHideTimeout);
        },
        handleBarMouseOut() {
            if (this.activeBar) {
                d3.select(this.activeBar).attr("opacity", 1);
            }

            this.activeBar = null;

            this.tooltipHideTimeout = setTimeout(() => {
                this.tooltipContent = "";
                this.tooltipStyle.opacity = 0;
            }, 200);
        },
        updateTooltipPosition(event) {
            this.tooltipStyle.left = event.pageX + "px";
            this.tooltipStyle.top = event.pageY + "px";
        },
    },
};
</script>

<style lang="scss" scoped>
.histogramContainer {
    // display: flex;
    flex-direction: row;
    align-items: center;
    height: 400px;
    width: 100%;
    // margin: 0 5%;
    // padding: 2%;
    // background: #ffffff;
    border-radius: 10px;
}

.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
}

.y-axis {
    font-weight: bold;
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
    font-size: 12px;
}

.color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.data-value {
    margin-left: 5px;
}

.bar {
    cursor: pointer;
}
</style>
