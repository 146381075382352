<template>
    <div class="chart-Container">
        <div class="chart-container">
            <svg ref="diskChart" width="300" height="300">
                <g ref="chartGroup"></g>
            </svg>
            <div class="tooltip" :style="tooltipStyle">
                {{ tooltipContent }}
            </div>
        </div>
        <div class="description-container">
            <div v-for="(item, index) in diskData" :key="index" class="description">
                <div :style="{ backgroundColor: getColor(index) }" class="color-box"></div>
                <div class="data-label">{{ item.label }}:</div>
                <div class="data-value">{{ item.value }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    name: "DiskVue",
    data() {
        return {
            diskData: [
                { label: "Data A", value: 30 },
                { label: "Data B", value: 50 },
                { label: "Data C", value: 20 },
            ],
            tooltipStyle: {
                opacity: 0,
                left: "0px",
                top: "0px",
            },
            tooltipContent: "", // Content to display in the tooltip
            activeSlice: null, // Track the currently active slice
            tooltipHideTimeout: null, // Timeout to hide the tooltip
        };
    },
    mounted() {
        const svg = d3.select(this.$refs.diskChart);
        const width = +svg.attr("width");
        const height = +svg.attr("height");
        const radius = Math.min(width, height) / 2;
        const g = d3.select(this.$refs.chartGroup).attr("transform", `translate(${width / 2},${height / 2})`);

        const pie = d3.pie().value((d) => d.value);
        const arc = d3.arc().innerRadius(0).outerRadius(radius).padAngle(0.02).cornerRadius(10);

        const pieData = pie(this.diskData);

        const slices = g
            .selectAll("path")
            .data(pieData)
            .enter()
            .append("path")
            .attr("d", arc)
            .attr("fill", (d, i) => this.getColor(i))
            .attr("stroke", "#fff")
            .attr("stroke-width", 2);

        // Add percentage labels to the slices
        g.selectAll("text.text-percentrage")
            .data(pieData)
            .enter()
            .append("text")
            .attr("class", "text-percentrage")
            .attr("transform", (d) => `translate(${arc.centroid(d)})`)
            .attr("dy", "0.35em")
            .attr("text-anchor", "middle")
            .text((d) => `${parseInt(((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100)}%`);

        slices
            .on("mouseover", (event, d) => this.handleSliceHover(event, d))
            .on("mouseout", () => this.handleSliceMouseOut())
            .on("click", (event, d) => this.handleSliceClick(event, d));

        document.addEventListener("mousemove", (event) => {
            if (this.tooltipStyle.opacity > 0) {
                this.updateTooltipPosition(event);
            }
        });
    },
    methods: {
        getColor(index) {
            const colors = ["#F090DA", "#AD6BB9", "#3968FA"];
            return colors[index % colors.length];
        },

        handleSliceHover(event, d) {
            if (this.activeSlice && this.activeSlice !== event.target) {
                const originalArc = this.getOriginalArc();
                d3.select(this.activeSlice).transition().duration(50).attr("d", originalArc);
            }

            const expandedArc = this.getExpandedArc();
            d3.select(event.target).transition().duration(100).attr("d", expandedArc);

            this.activeSlice = event.target;

            console.log({ d });
            this.tooltipContent = `${d.data.label}: ${d.data.value}`;
            this.tooltipStyle.opacity = 0.9;
            this.showDetailWindow = true;
            clearTimeout(this.tooltipHideTimeout);
        },

        handleSliceMouseOut() {
            this.tooltipHideTimeout = setTimeout(() => {
                if (this.activeSlice) {
                    const originalArc = this.getOriginalArc();
                    d3.select(this.activeSlice).transition().duration(50).attr("d", originalArc);
                }

                this.activeSlice = null;
                this.tooltipStyle.opacity = 0;
            }, 500);
        },

        handleSliceClick(event, d) {
            this.tooltipContent = `${d.data.label}: ${d.data.value}`;
            this.tooltipStyle.opacity = 0.9;
            clearTimeout(this.tooltipHideTimeout);
        },

        updateTooltipPosition(event) {
            this.tooltipStyle.left = event.pageX + "px";
            this.tooltipStyle.top = event.pageY + "px";
        },

        getExpandedArc() {
            const radius = Math.min(this.$refs.diskChart.width.baseVal.value, this.$refs.diskChart.height.baseVal.value) / 2;
            return d3
                .arc()
                .innerRadius(0)
                .outerRadius(radius * 1.05)
                .padAngle(0.02)
                .cornerRadius(10);
        },

        getOriginalArc() {
            const radius = Math.min(this.$refs.diskChart.width.baseVal.value, this.$refs.diskChart.height.baseVal.value) / 2;
            return d3.arc().innerRadius(0).outerRadius(radius).padAngle(0.02).cornerRadius(10);
        },
    },
};
</script>

<style lang="scss" scoped>
.chart-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 300px;
}

.description-container {
    margin-top: 100px;
}

.tooltip {
    position: absolute;
    background-color: #e9e6e6;
    color: black;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 5px;
}

.description {
    display: flex;
    align-items: center;
    margin: 5px;
}

.color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
}

.data-label {
    font-weight: bold;
}

.data-value {
    margin-left: 5px;
}

.text-percentrage {
    font-size: 18px;
    fill: #fff;
    font-weight: bold;
}
</style>
