<template>
    <div>
        <div class="donut-Container">
            <div class="chart-container">
                <svg style="overflow: visible" ref="diskChart" width="220" height="300">
                    <g ref="chartGroup"></g>
                </svg>
                <div class="tooltip" :style="tooltipStyle">
                    {{ tooltipContent }}
                </div>
            </div>
            <div class="description-container" v-if="label">
                <div v-for="(item, index) in diskData" :key="index" class="description">
                    <div :style="{ backgroundColor: getColor(index) }" class="color-box"></div>
                    <div class="data-label">{{ item.label }}:</div>
                    <div class="data-value">{{ item.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";
import { useStore } from "@/store";

export default {
    name: "DonutChart",

    props: {
        diskData: Array,
        project: String,
        label: Boolean,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    computed: {
        // diskData(){
        //     return this.Store.createDistributionData(this.project)}
    },
    data() {
        return {
            // diskData:[],
            tooltipStyle: {
                opacity: 0,
                left: "0px",
                top: "0px",
            },
            tooltipContent: "", // Content to display in the tooltip
            activeSlice: null, // Track the currently active slice
            tooltipHideTimeout: null, // Timeout to hide the tooltip
        };
    },
    watch: {
        diskData() {
            this.renderChart();
        },
    },
    beforeUnmount() {
        // this.diskData=[]
    },
    mounted() {
        // this.diskData= this.Store.createDistributionData(this.project)
        this.renderChart();
    },
    methods: {
        renderChart() {
            const svg = d3.select(this.$refs.diskChart);
            const width = +svg.attr("width");
            const height = +svg.attr("height");
            const radius = Math.min(width, height) / 2;
            const g = d3.select(this.$refs.chartGroup).attr("transform", `translate(${width / 2},${height / 2})`);

            const pie = d3.pie().value((d) => d.value);
            const arc = d3
                .arc()
                .innerRadius(radius / 1.5)
                .outerRadius(radius)
                .padAngle(0.02)
                .cornerRadius(10);

            const pieData = pie(this.diskData);

            const slices = g
                .selectAll("path")
                .data(pieData)
                .enter()
                .append("path")
                .attr("d", arc)
                .attr("fill", (d, i) => this.getColor(i));

            slices
                .on("mouseover", (event, d) => this.handleSliceHover(event, d))
                .on("mouseout", () => this.handleSliceMouseOut())
                .on("click", (event, d) => this.handleSliceClick(event, d));

            // Add a global mousemove event listener to keep the tooltip visible
            document.addEventListener("mousemove", (event) => {
                if (this.tooltipStyle.opacity > 0) {
                    this.updateTooltipPosition(event);
                }
            });
        },
        getColor(index) {
            const colors = ["#2196f3", "#05CD99", "#EABC49"];
            return colors[index % colors.length];
        },
        handleSliceHover(event, d) {
            // If there's an active slice and it's not the current slice, reset it to the original size
            if (this.activeSlice && this.activeSlice !== event.target) {
                const originalArc = this.getOriginalArc();
                d3.select(this.activeSlice)
                    .transition()
                    .duration(50) // Reduce transition time
                    .attr("d", originalArc);
            }

            // Slightly increase the radius of the hovered slice
            const expandedArc = this.getExpandedArc();
            d3.select(event.target)
                .transition()
                .duration(100) // Reduce transition time
                .attr("d", expandedArc);

            // Set the active slice to the current slice
            this.activeSlice = event.target;

            // Set the tooltip content
            this.tooltipContent = `${d.data.label}: ${d.data.value}`;
            this.tooltipStyle.opacity = 0.9;
            this.showDetailWindow = true;
            // Clear the tooltip hide timeout
            clearTimeout(this.tooltipHideTimeout);
        },
        handleSliceMouseOut() {
            // Start a timeout to hide the tooltip after a brief delay
            this.tooltipHideTimeout = setTimeout(() => {
                // Reset the active slice to its original size
                if (this.activeSlice) {
                    const originalArc = this.getOriginalArc();
                    d3.select(this.activeSlice)
                        .transition()
                        .duration(50) // Reduce transition time
                        .attr("d", originalArc);
                }

                // Clear the active slice and hide the tooltip
                this.activeSlice = null;
                this.tooltipStyle.opacity = 0;
            }, 500); // Adjust the delay as needed
        },
        handleSliceClick(event, d) {
            // Set the tooltip content
            this.tooltipContent = `${d.data.label}: ${d.data.value}`;
            this.tooltipStyle.opacity = 0.9;

            // Clear the tooltip hide timeout
            clearTimeout(this.tooltipHideTimeout);
        },
        updateTooltipPosition(event) {
            this.tooltipStyle.left = event.pageX + "px";
            this.tooltipStyle.top = event.pageY + "px";
        },
        getExpandedArc() {
            const radius = Math.min(this.$refs.diskChart.width.baseVal.value, this.$refs.diskChart.height.baseVal.value) / 2;
            return d3
                .arc()
                .innerRadius(radius / 1.5)
                .outerRadius(radius * 1.05) // Increase the radius slightly when hovered
                .padAngle(0.02)
                .cornerRadius(10);
        },
        getOriginalArc() {
            const radius = Math.min(this.$refs.diskChart.width.baseVal.value, this.$refs.diskChart.height.baseVal.value) / 2;
            return d3
                .arc()
                .innerRadius(radius / 1.5)
                .outerRadius(radius)
                .padAngle(0.02)
                .cornerRadius(10);
        },
    },
};
</script>

<style lang="scss" scoped>
// .donut {
//   background: #fefeff;
//   border: 0.6px solid rgba(0, 0, 0, 0.18);
//   box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.03);
//   border-radius: 10px;
//   padding: 3%;
//   padding-left: 8%;
// }

// .donut > div {
//   display: grid;
// }

.donut-chart {
    justify-items: center;
    align-items: center;
    margin: 9%;
}

.donut-Container {
    display: flex;
    flex-direction: row;
    overflow: visible;
}
.description-container {
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
}
.description {
    display: flex;
    align-items: center;
    margin: 5px;
}

.color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.data-label {
    font-weight: bold;
}

.data-value {
    margin-left: 5px;
}

.chart-container {
    overflow: visible;
}
.chart-svg-pie-task-done {
    width: 100%;
    height: 100%;
}

.little-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #5c5f62;
}

.big-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #000000;
}
</style>
